import { Component, OnInit } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Student } from './interfaces/student.interface';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit,
  faUsers,
  faTrash,
  faSearch,
  faPlus,
  faMinus,
  faCalendarAlt,
  faGamepad,
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TutorialComponent } from 'src/app/shared/models/tutorial.model';
import { isEmpty } from 'lodash';
import { User } from 'src/app/shared/models/user.model';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm.dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { CalendarDialogComponent } from './student-profile/calendar-dialog/calendar.dialog';
import { SAVE_MODE } from './student-profile/calendar-dialog/consts/save-mode';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { get } from 'lodash';
import { ThemeService } from 'src/app/services/themes/themes.service';
import { Theme } from '../configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { TutorialsService } from 'src/app/services/tutorials/tutorials.service';
import { StudentHelperService } from 'src/app/services/student/student-helper.service';
import { Token } from 'src/app/shared/models';

import { MessagesService } from '../messages/messages.service';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit {
  public readonly displayedColumns: string[] = ['name', 'nickname', 'age', 'tag', 'actions'];
  public readonly usersIcon: IconDefinition = faUsers;
  public readonly editIcon: IconDefinition = faEdit;
  public readonly searchIcon: IconDefinition = faSearch;
  public readonly trash: IconDefinition = faTrash;
  public readonly plusIcon: IconDefinition = faPlus;
  public readonly minusIcon: IconDefinition = faMinus;
  public readonly calendarIcon: IconDefinition = faCalendarAlt;
  public readonly gamepadIcon: IconDefinition = faGamepad;

  public students: MatTableDataSource<Student>;
  public tutorial: TutorialComponent;
  public showTutorial = false;
  public onCompleteTutorial: () => void;
  public user: User;
  public org: any = 'loading';
  public themes: Theme[];
  public selectedStudent: any;
  public today = new Date();

  private _pendingWork = false;

  constructor(
    protected _snackBar: MatSnackBar,
    private _rest: RestAPIService,
    private _dialog: MatDialog,
    private _router: Router,
    private _roles: RoleService,
    private _confirm: ConfirmationService,
    private _logger: LoggerService,
    private themeService: ThemeService,
    private tutorialService: TutorialsService,
    private studentHelper: StudentHelperService,
    private messageService: MessagesService,
  ) {}

  async ngOnInit() {
    this.user = this._roles.user;

    if (get(this.user, 'organization')) {
      this._router.navigate(['/users']);

      return;
    }

    this._loadStudents();
    this._setupTutorial();
    this.org = await this._rest
      .get('organization/self', { msg: 'Could not get organization.' })
      .catch(() => (this.org = null));
    await this.loadThemes();
    await this.messageService.initUserMessage();
  }

  public acceptTerms() {
    this._confirm
      .createConfirmation(
        'Terms of Service',
        'You must agree with our &nbsp; <a target="_blank" href="/terms-of-service"> Terms of Service </a>  to continue with access to your LSWorks portal',
        'I agree',
        undefined,
        '400px',
        true,
      )
      .then(async () => {
        try {
          this.user.patron.acceptedTerms = true;
          await this._rest.put(
            'patron/self',
            {
              patron: this.user.patron,
            },
            { msg: 'Could not put organization' },
          );
          // eslint-disable-next-line no-empty
        } catch {}
      });
  }

  public isOrgClient(): boolean {
    if (!this._roles.access) {
      return true;
    }
    return this._roles.access.level === 'B2B' && this._roles.access.role === 'Client';
  }

  public getAgeFromBirthday(student: Student): string {
    const studentAge = this.studentHelper.getStudentAge(student);
    return studentAge.toString();
  }

  public presentArchiveStudent(student: Student): void {
    const canArchive = !this.canDisassociateStudent(student);

    if (canArchive) {
      const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        width: '250px',
        data: 'Are you sure you want to archive this student?',
      });

      dialogRef.afterClosed().subscribe((answer: boolean) => {
        if (answer) {
          this._archiveStudent(student);
        }
      });
    } else {
      this._dialog.open(AlertDialogComponent, {
        width: '350px',
        data: 'The assigned credit should be retrieved by clicking on the - sign before archiving the student.',
      });
    }
  }

  public async associateStudent(student: Student): Promise<void> {
    try {
      if (this._pendingWork) {
        return;
      }

      this._pendingWork = true;
      const response = await this._rest.get('token/self', { msg: 'Could not get token.' });
      if (!response.tokens || response.tokens.length === 0) {
        this._pendingWork = false;
        this._router.navigate(['programs-pricing']);
        return;
      }

      const availableToken = response.tokens.find((t: Token) => !t.studentId && t.paymentConfirmed);
      if (!availableToken) {
        this._pendingWork = false;
        this._router.navigate(['programs-pricing']);
      } else {
        const allowHomeAccess = get(student, 'enableHomeAccess', false);

        await this._rest.put(`token/${availableToken.id}/student/${student.id}/allowHomeAccess/${allowHomeAccess}`, {
          msg: 'Could associate the token to this student, please try again',
        });

        this._loadStudents();
      }
    } catch {
      this._pendingWork = false;
    }
  }

  public canDisassociateStudent(student: Student): boolean {
    return student.tokens && student.tokens.length > 0 && student.progress && student.progress.length === 0;
  }

  public async disassociateStudent(student: Student): Promise<void> {
    try {
      if (this._pendingWork) {
        return;
      }
      this._pendingWork = true;

      const token = student.tokens[0];
      await this._rest.put('token/' + token.id + '/disassossiate', {
        msg: 'Could not put student token.',
      });
      await this._loadStudents();
    } catch {
      this._pendingWork = false;
    }
  }

  public async goToStudentProfile(student: Student): Promise<void> {
    delete student.archived;
    delete student.programs;
    delete student.progress;

    const themeLabel = this.getThemeLabel(student);

    const queryParams = await this.studentHelper.getProfileQueryParams(student, themeLabel);

    this._router.navigate(['/students/profile'], { queryParams });
  }

  public getThemeLabel(student: Student) {
    let theme = this.themes.find((t) => t.id === student.theme);

    if (!theme) {
      theme = this.themeService.getClassicTheme();
    }

    return theme.label.en_ca;
  }

  private async _archiveStudent(student: Student): Promise<void> {
    try {
      await this._rest.put(
        'student/archive/' + student.id,
        {},
        {
          msg: 'Could not put student/archive/:studentId.',
        },
      );
      await this._loadStudents();
      // eslint-disable-next-line no-empty
    } catch {}
  }

  private _setupTutorial() {
    const { lastLogin } = this.user;
    const showTutorial: boolean = isEmpty(lastLogin);

    if (showTutorial) {
      this.tutorialService.setupTutorial(this.user);
    } else if (get(this.user, 'patron.acceptedTerms', false) === false) {
      this.acceptTerms();
    }
  }

  private async _loadStudents(): Promise<void> {
    try {
      const response = await this._rest.get('student/self', { msg: 'Could not get student.' });
      this.students = new MatTableDataSource(get(response, 'students', []));
      this.students.filterPredicate = (student: Student, filter: string): boolean => {
        return (
          (student.givenName && student.givenName.toLowerCase().includes(filter)) ||
          (student.familyName && student.familyName.toLowerCase().includes(filter)) ||
          (student.nickname && student.nickname.toLowerCase().includes(filter))
        );
      };
    } finally {
      this._pendingWork = false;
    }
  }

  public applyFilter(filterValue: string) {
    this.students.filter = filterValue.trim().toLowerCase();
  }

  public async loadThemes() {
    this.themes = await this.themeService.getEnabledThemes();
  }

  public openAgenda(student) {
    if (!student.agenda) {
      return;
    }

    const dialog = this._dialog.open(CalendarDialogComponent, {
      data: {
        agenda: student.agenda,
        saveMode: SAVE_MODE.UPDATE,
      },
    });

    dialog.afterClosed().subscribe(async (shouldUpdate: boolean) => {
      if (shouldUpdate) {
        try {
          await this._rest.put(`student/${student.id}`, { student }, { msg: 'Could not put student' });

          this._snackBar.open('Student agenda has been saved', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.ngOnInit();
        } catch (error) {
          this._logger.error(error);
        }
      }
    });
  }
}
