import { Component, OnInit, ViewChild } from '@angular/core';
import { isEmpty, get } from 'lodash';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { StudentsListService } from '../clients-menu/students-list/students-list.service';
import { SchoolStudent } from 'src/app/core/openapi';
import { Subscription } from 'rxjs';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { MatDialog } from '@angular/material/dialog';
import { StudentCreateModalComponent } from '../../modals/student-create-modal/student-create-modal.component';
import { ManagerActions } from 'src/app/shared/interfaces/Manager.interface';
import { UsersHelperService } from '../../users-helper.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/shared/models';
import { StudentHelperService } from 'src/app/services/student/student-helper.service';

@Component({
  selector: 'students-menu',
  templateUrl: './students-menu-component.html',
  styleUrls: ['./students-menu-component.scss'],
})
export class StudentsMenuComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<SchoolStudent>;

  public dataSource: MatTableDataSource<SchoolStudent> = new MatTableDataSource([]);
  public originalDataSource: MatTableDataSource<SchoolStudent> = new MatTableDataSource([]);

  private refreshSubscription: Subscription;

  public loading = true;
  public isLoadingImport: boolean = false;
  public isLoadingExport: boolean = false;
  public isDropdownOpen: boolean = false;
  public searchTerm: string;
  public user: User;

  constructor(
    private _confirm: ConfirmationService,
    private studentListService: StudentsListService,
    private dialog: MatDialog,
    private _usersHelperService: UsersHelperService,
    private authService: AuthService,
    private studentHelperService: StudentHelperService,
  ) {}

  async ngOnInit() {
    this.subscribeEvents();
    this.user = await this.authService.getUser();

    await this.getStudents();
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  public editClientStudent(student: Student): void {
    const dialogRef = this.dialog.open(StudentCreateModalComponent, {
      data: {
        type: ManagerActions.UPDATE,
        student: student,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result?.student) {
        this.loading = true;
        this.dataSource.data = await this.studentListService.showSchoolStudents(true);
        this.loading = false;
      }
    });
  }

  public createStudent(): void {
    const dialogRef = this.dialog.open(StudentCreateModalComponent, {
      data: {
        type: ManagerActions.CREATE,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result?.student) {
        this.loading = true;
        this.dataSource.data = await this.studentListService.showSchoolStudents(true);
        this.loading = false;
      }
    });
  }

  public async getStudents(refresh: boolean = false) {
    const students = await this.studentListService.showSchoolStudents(refresh);

    const normalizedStudents = students.map((student) => {
      return {
        ...student,
        fullname: `${get(student, 'givenName', '')} ${get(student, 'familyName', '')}`,
      };
    });
    this.dataSource.data = normalizedStudents;
    this.originalDataSource.data = this.dataSource.data;

    if (this.table) {
      this.table.renderRows();
    }

    this.loading = false;
  }

  public subscribeEvents() {
    this.refreshSubscription = this.studentListService.refreshSchoolList.subscribe(async () => {
      this.loading = true;
      await this.getStudents(true);
    });
  }

  public canAccessNeuralign(student: Student): boolean {
    return student.tokens?.length > 0;
  }

  public accessNeuralign(student: Student): void {
    this._usersHelperService.goToStudentProgramPage(student);
  }

  public async deleteStudent(student: SchoolStudent): Promise<void> {
    const studentHasAssignedCredit = student.tokens?.length > 0;
    const studentHasProgress = student.hasProgress;

    if (studentHasAssignedCredit && !studentHasProgress) {
      this._confirm.createConfirmation(
        'Warning',
        'The assigned credit should be retrieved by clicking on the - sign before archiving the student.',
        'Close',
        undefined,
      );
    } else {
      this._confirm
        .createConfirmation(
          'Warning',
          `Are you sure you want to delete ${!isEmpty(student.fullname) ? student.fullname : 'this student'}?`,
          'Yes',
          'No',
          '350px',
        )
        .then(async () => {
          this.loading = true;

          await this.studentListService.archiveStudent(student);

          await this.getStudents(true);
        });
    }
  }

  public getStudentAge(student: Student) {
    return this.studentHelperService.getStudentAge(student);
  }

  search() {
    this.dataSource.data = this.handleStudentSearch(this.originalDataSource.data, this.searchTerm);
  }

  public handleStudentSearch(items: SchoolStudent[], searchTerm: string) {
    if (!items) return [];
    if (!searchTerm) return items;

    searchTerm = searchTerm.toLowerCase();
    return items.filter((it) => {
      const matchSchool = it?.school?.toLowerCase().includes(searchTerm);
      const matchFullname = it?.fullname?.toLowerCase().includes(searchTerm);
      return matchSchool || matchFullname;
    });
  }

  public exportStudents(): void {
    this.isLoadingExport = true;
    setTimeout(() => {
      this.studentListService.exportStudents(this.dataSource.data);
      this.isLoadingExport = false;
      this.toggleDropdown();
    }, 1000);
  }

  public downloadStudentFile(): void {
    this.studentListService.downloadStudentFile(true);
    this.toggleDropdown();
  }

  public async importStudents(event: Event) {
    this.isLoadingImport = true;
    const createdStudents = await this.studentListService.importStudentsForFile(event);
    if (!createdStudents) {
      this.isLoadingImport = false;
      this.toggleDropdown();

      return;
    }
    this.isLoadingImport = false;
    this.toggleDropdown();
  }

  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public triggerFileInput() {
    const fileInput = document.getElementById('fileInputStudents') as HTMLInputElement;
    fileInput.click();
  }

  public closeDropdown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const dropdown = document.querySelector('.dropdown-container');

    if (dropdown && !dropdown.contains(target)) {
      this.isDropdownOpen = false;
    }
  }

  public allowActions(student: Student): boolean {
    if (!this.user) {
      return false;
    }

    const organization = this.user.organization;
    const isSchoolBoard: boolean = get(organization, 'isSchool', false) && get(organization, 'isReseller', false);

    if (!isSchoolBoard) {
      return true;
    }

    return get(organization, 'id', '') === get(student, 'organizationId', '');
  }
}
