<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Students', url: '/students' }
  ]"
>
  <div
    *ngIf="!loading"
    class="mainContainer"
  >
    <div
      class="loading"
      *ngIf="tableLoading"
    >
      <div class="background"></div>
      <fa-icon
        [icon]="spinner"
        [spin]="true"
      ></fa-icon>
    </div>

    <div
      class="inner-content py-3"
      *ngIf="logs"
    >
      <div class="content">
        <div class="overview">
          <div class="mb-2 p-2 categoryContainer">
            <mat-select
              class="mx-2 categorySelectable"
              placeholder="Category"
              [(ngModel)]="selectedOption"
              floatlLabel="never"
              (selectionChange)="reloadTable()"
            >
              <mat-option
                *ngFor="let option of options"
                [value]="option"
              >
                {{ getOptionName(option) }}
              </mat-option>
            </mat-select>
          </div>

          <h4 class="title">OVERVIEW</h4>
          <div class="info">
            <p>
              {{ getActiveUsers() }}<br />
              <small> Active Users ( Last hour ) </small>
            </p>
            <p>
              {{ studentsInProgress }}<br />
              <small> Students on {{ getOptionName(selectedOption) }} </small>
            </p>
          </div>

          <h4 class="title">TODAY</h4>
          <div class="info">
            <p>
              {{
                this.processLogs(this.logs, selectedOption + ' progress', 1)
                  .count
              }}<br />
              <small> {{ getOptionName(selectedOption) }} Login </small>
            </p>
          </div>

          <h4 class="title">WEEK</h4>
          <div class="info">
            <p>
              {{
                this.processLogs(this.logs, selectedOption + ' progress', 7)
                  .count
              }}<br />
              <small> {{ getOptionName(selectedOption) }} Login </small>
            </p>
          </div>
        </div>
        <div class="graph">
          <canvas id="DashboardResults"></canvas>
        </div>
      </div>
    </div>

    <div
      class="filters d-flex justify-content-end align-items-center px-5 position-relative"
    >
      <input
        *ngIf="isSchoolBoard"
        [(ngModel)]="schoolFilter"
        class="mx-2"
        placeholder="Search by school"
        (keyup)="applyFilter()"
      />
      <input
        [(ngModel)]="fullnameFilter"
        class="mx-2"
        placeholder="Search by name"
        (keyup)="applyFilter()"
      />
      <mat-select
        class="mx-2"
        placeholder="Grade"
        [(ngModel)]="gradeFilter"
        (selectionChange)="applyFilter()"
        floatlLabel="never"
      >
        <mat-option
          *ngFor="let grade of getGrades()"
          [value]="grade"
        >
          {{ grade }}
        </mat-option>
      </mat-select>

      <fa-icon
        (click)="clearGradeFilter()"
        [ngClass]="{ disabledIcon: !gradeFilter }"
        [icon]="clear"
        class="clearIcon"
      ></fa-icon>
    </div>

    <div class="table px-3 mt-3">
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <mat-header-cell
            *matHeaderCellDef
            class="d-flex justify-content-center"
          >
            Name
          </mat-header-cell>
          <mat-cell
            *matCellDef="let student"
            class="d-flex justify-content-center"
          >
            {{ student.fullname }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="grade">
          <mat-header-cell
            *matHeaderCellDef
            class="d-flex justify-content-center"
          >
            Grade
          </mat-header-cell>
          <mat-cell
            *matCellDef="let student"
            class="d-flex justify-content-center"
          >
            <span>{{ student.grade || '---' }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="last_session">
          <mat-header-cell
            *matHeaderCellDef
            class="d-flex justify-content-center"
          >
            Last session
          </mat-header-cell>
          <mat-cell
            *matCellDef="let student"
            class="d-flex justify-content-center"
          >
            <span> {{ student.lastSessionDate }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="session">
          <mat-header-cell
            *matHeaderCellDef
            class="d-flex justify-content-center"
          >
            Session
          </mat-header-cell>
          <mat-cell
            *matCellDef="let student"
            class="d-flex justify-content-center"
          >
            <span>
              {{
                student.lastSession ? student.lastSession.session : '---'
              }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="points">
          <mat-header-cell
            *matHeaderCellDef
            class="d-flex justify-content-center"
          >
            Points
          </mat-header-cell>
          <mat-cell
            *matCellDef="let student"
            class="d-flex justify-content-center"
          >
            <span> {{ student.points }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="change">
          <mat-header-cell
            *matHeaderCellDef
            class="d-flex justify-content-center"
          >
            Change
          </mat-header-cell>
          <mat-cell
            *matCellDef="let student"
            class="d-flex justify-content-center"
          >
            <span
              [ngClass]="{
                positiveChange: checkScoreChange(student.scoreChange) > 0,
                negativeChange: checkScoreChange(student.scoreChange) < 0
              }"
            >
              {{ student.scoreChange }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="details">
          <mat-header-cell
            *matHeaderCellDef
            class="d-flex justify-content-center"
          >
            Details
          </mat-header-cell>
          <mat-cell
            *matCellDef="let student"
            class="d-flex justify-content-center px-0"
          >
            <span class="button">
              <app-simple-button
                [disabled]="getStudentProgress(student).length === 0"
                (click)="openFullResultChart(student)"
                class="mx-auto"
                [text]="'More info'"
              >
              </app-simple-button>
            </span>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="
            [
              'name',
              'grade',
              'last_session',
              'session',
              'points',
              'change',
              'details'
            ];
            sticky: true
          "
        ></mat-header-row>
        <mat-row
          *matRowDef="
            let row;
            columns: [
              'name',
              'grade',
              'last_session',
              'session',
              'points',
              'change',
              'details'
            ]
          "
        ></mat-row>
        <tr
          class="mat-row d-flex"
          *matNoDataRow
        >
          <td
            class="mat-cell footerRow p-3"
            colspan="4"
            *ngIf="studentData.length > 0"
          >
            No data matching the filter
          </td>

          <td
            class="mat-cell footerRow p-5 mx-auto"
            colspan="4"
            *ngIf="studentData.length === 0"
          >
            You dont have any students
          </td>
        </tr>
      </mat-table>
    </div>
  </div>

  <div
    class="loading"
    *ngIf="loading"
  >
    <app-loading-indicator></app-loading-indicator>
  </div>
</app-main-layout>
