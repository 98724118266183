import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ThemeService } from 'src/app/services/themes/themes.service';
import { get } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { StudentHelperService } from 'src/app/services/student/student-helper.service';

@Injectable({
  providedIn: 'root',
})
export class B2cStudentsListService {
  students: Student[];
  currentB2CClient;

  constructor(
    private _logger: LoggerService,
    private _rest: RestAPIService,
    private _router: Router,
    private _themeService: ThemeService,
    private _dialog: MatDialog,
    private studentHelper: StudentHelperService,
  ) {}

  public async loadStudents(clientId: string, options?: { refresh: boolean }): Promise<Student[]> {
    // Update if no current data or forced refresh
    if (options?.refresh || !this.students) {
      // Save selected client id for other methods to use
      this.currentB2CClient = clientId;

      try {
        // Get students
        const response = await this._rest.get(`/patron/${clientId}/students`);

        if (!response || !response.students) {
          return [];
        }
        // Transform student data for template to consume
        this.students = response.students.map((student: Student) => {
          return {
            ...student,
            name: this._transformStudentName(student),
            age: this._transformStudentAge(student),
          };
        });
      } catch (error) {
        throw new Error(error.message);
      }
    }
    return this.students;
  }

  public async addStudentProgram(student: Student): Promise<void> {
    try {
      const response = await this._rest.get('token/self', { msg: 'Could not get token.' });
      if (!response.tokens || response.tokens.length === 0) {
        this._router.navigate(['programs-pricing']);
        return;
      }
      const availableToken = response.tokens.find((t) => !t.studentId && t.paymentConfirmed);
      if (!availableToken) {
        this._router.navigate(['programs-pricing']);
      } else {
        await this._rest.put(`token/${availableToken.id}/student/${student.id}/allowHomeAccess/${false}`, {
          msg: 'Could associate the token to this student, please try again.',
        });
        const updatedStudent = await this._getUpdatedStudent(student.id);

        if (updatedStudent) {
          student.tokens = updatedStudent.tokens;
        }
      }
    } catch (err) {
      this._logger.error(err);
    }
  }

  public async removeStudentProgram(student: Student): Promise<void> {
    try {
      const token = student.tokens[0];
      await this._rest.put('token/' + token.id + '/disassossiate', {
        msg: 'Could not put student token.',
      });
      const updatedStudent = await this._getUpdatedStudent(student.id);

      if (updatedStudent) {
        student.tokens = updatedStudent.tokens;
      }
    } catch (err) {
      this._logger.error(err);
    }
  }

  public async editStudent(student: Student): Promise<void> {
    const themeLabel = await this._getStudentThemeLabel(student);

    const studentFormControlValues = this._getStudentFormControlValues(student);

    const queryParams = await this.studentHelper.getProfileQueryParams(studentFormControlValues as Student, themeLabel);

    this._router.navigate(['/students/profile/' + this.currentB2CClient], {
      queryParams,
    });
  }

  public accessNeuralign(student: Student): void {
    if (this.doesStudentHaveTokens(student)) {
      this._router.navigate(['/students/programs/' + this.getStudentName(student) + '/' + student.id]);
    } else {
      this._dialog.open(AlertDialogComponent, {
        data: 'This student dont have associated tokens',
        panelClass: 'modal-border',
        width: '400px',
        height: '230px',
      });
    }
  }

  public async archiveStudent(student: Student): Promise<void> {
    try {
      await this._rest.put('student/archive/' + student.id, {}, { msg: 'Could not put student/archive.' });
    } catch (err) {
      this._logger.error(err);
    }
  }

  public canDeleteStudent(student: Student): boolean {
    return student.tokens?.length < 1;
  }

  public doesStudentHaveTokens(student: Student): boolean {
    return student.tokens && student.tokens.length > 0;
  }

  public doesStudentHaveProgress(student: Student): boolean {
    return student.progress && student.progress.length > 0;
  }

  private async _getUpdatedStudent(id: string): Promise<any> {
    try {
      const response = await this._rest.get(`student/${id}`);

      const student = get(response, 'student', {});
      const tokens = get(response, 'tokens', []);

      return { student, tokens };
    } catch (err) {
      this._logger.error(err);
    }
  }

  private async _getStudentThemeLabel(student: Student): Promise<any> {
    const themes = await this._themeService.getEnabledThemes();

    let theme = themes.find((t) => t.id === student.theme);

    if (!theme) {
      theme = this._themeService.getClassicTheme();
    }

    return theme.label.en_ca;
  }

  private _getStudentFormControlValues(student: Student): Record<string, any> {
    // When we go to edit a student we have to pass in only some of the student's
    // properties so that the StudentProfileComponent can load its form.
    return {
      id: student.id,
      familyName: student.familyName,
      givenName: student.givenName,
      fullname: student.fullname,
      nickname: student.nickname,
      birthdate: student.birthdate,
      image: student.image,
      language: student.language,
      createdBy: student.createdBy,
      alertOnSessionEnd: student.alertOnSessionEnd,
      theme: student.theme,
      patronId: student.patronId,
      tokens: student.tokens,
    };
  }

  private _transformStudentName(student: Student): string {
    if (student?.fullname) {
      return student.fullname;
    } else {
      return student.givenName + ' ' + student.familyName;
    }
  }

  private _transformStudentAge(student: Student): string {
    const studentAge = this.studentHelper.getStudentAge(student);
    return studentAge.toString();
  }

  private getStudentName(student: Student): string {
    if (this._isStudentNickNameNotEmpty(student)) {
      return student.nickname;
    } else {
      return student.givenName;
    }
  }

  private _isStudentNickNameNotEmpty(student: Student): boolean {
    return student.nickname && student.nickname.trim() !== '';
  }
}
